import vxFileUpload from '../../../../components/common/vx-file-upload/vx-file-upload.vue';

export default {
  name: 'vx-contactus-upload',
  components: {
    vxFileUpload,
  },
  props: {
    // Text coming from property file
    i18n: {
      type: Object,
      default: {},
    },
    // Files attached
    attachments: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      isFileSizeExceeded: false,
      fileSizeLimit: 3,
      isFileNameDuplicate: false,
      filesAttached: [],
      fileSizeUnit: '',
      fileType: '.jpg, .jpeg, .gif, .png, .txt, .doc, .docx, .pdf, .ppt,.pptx, .xls, .xlsx, .csv',
      disableAttach: false,
      fileSize: '',
    };
  },

  mounted() {

  },
  methods: {
    /**
     * This function emits the file uploaded
     * @param  {Array} fileList data of the file uploaded
     */
    fileUpload(fileList) {
      this.$emit('upload', fileList);
    },
    /**
     * This function calls functions to check if file name is duplicate, file size, and disables attachement of more than one file
     * @param  {Array} fileList data of the file uploaded
     */
    attachmentListUpdated(fileList) {
      this.isFileSizeExceeded = this.validateFileSize(fileList, this.getFileSize(fileList));
      if (this.isFileNameDuplicate) {
        this.isFileNameDuplicate = false;
      }
      if (this.attachments.length && fileList.length) {
        this.validateFilename(this.attachments, fileList);
      }
      if (fileList.length) {
        this.disableAttach = true;
      } else {
        this.disableAttach = false;
      }
    },
    /**
     * This function checks file size is more than 3 MB
     * @param  {Array} fileList data of the file uploaded
     * @param  {String} fileSize File Size
     */
    validateFileSize(fileList, fileSize) {
      if (Number(fileSize) >= Number(this.fileSizeLimit)) {
        return true;
      }
      return false;
    },
    /**
     * This function gets file size
     * @param  {Array} fileList data of the file uploaded
     */
    getFileSize(fileList) {
      let sizeInMB = 0;
      let totalSizeInBytes = 0;
      let sizeInKB = 0;
      fileList.forEach((file) => { totalSizeInBytes += file.size; });
      sizeInKB = totalSizeInBytes / 1024 ** 1;
      sizeInMB = totalSizeInBytes / 1024 ** 2;
      if (sizeInMB < 1) {
        this.fileSizeUnit = this.i18n.fileSizeUnitKB;
        this.fileSize = sizeInKB.toFixed(2);
      } else {
        this.fileSizeUnit = this.i18n.fileSizeUnitMB;
        this.fileSize = sizeInMB.toFixed(2);
      }
      return sizeInMB.toFixed(2);
    },
    /**
     * This function checks is file name is duplicate
     * @param  {Array} attachments array of files attached
     * @param  {Array} fileList data of the file uploaded
     */
    validateFilename(attachments, fileList) {
      attachments.forEach((item) => {
        if (item.name === fileList[0].name) {
          this.isFileNameDuplicate = true;
        }
      });
    },
  },
};
