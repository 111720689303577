var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-file-upload" },
    [
      _vm.disableAttach
        ? _c("div", { staticClass: "division-border" })
        : _vm._e(),
      _vm._v(" "),
      !_vm.disableAttach
        ? _c("div", { staticClass: "mt-xs-5" }, [
            _c("form", [
              _c("div", { staticClass: "dropbox" }, [
                _c("input", {
                  staticClass: "input-file",
                  attrs: {
                    disabled: _vm.isError || _vm.duplicateNameError,
                    type: "file",
                    accept: _vm.fileType,
                    multiple: _vm.multipleFileUpload,
                    "aria-label": "drop box"
                  },
                  on: {
                    change: function($event) {
                      _vm.handleFileUpload($event.target.files)
                    }
                  }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "pt-xs-3 drop-box-text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.i18n.dragDropCaption) +
                      "\n        "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "upload-caption py-xs-3 text-left" }, [
              _c("label", { staticClass: "mb-xs-0" }, [
                _vm._v(_vm._s(_vm.i18n.fileAttachCaption1))
              ])
            ]),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "custom-file-upload",
                attrs: { tabindex: "0", for: "file-upload" }
              },
              [
                _c("i", { staticClass: "fa fa-cloud-upload" }),
                _vm._v(" " + _vm._s(_vm.i18n.fileAttachCaption2) + "\n    ")
              ]
            ),
            _vm._v(" "),
            _c("input", {
              staticClass: "hidden",
              attrs: {
                disabled: _vm.isError || _vm.duplicateNameError,
                type: "file",
                id: "file-upload",
                accept: _vm.fileType,
                multiple: _vm.multipleFileUpload
              },
              on: {
                change: function($event) {
                  _vm.handleFileUpload($event.target.files)
                }
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.files, function(file, index) {
        return _c(
          "div",
          { key: index, staticClass: "col-xs-12 px-xs-0 mt-xs-3" },
          [
            _c("div", {
              staticClass: "icon-attachment col-xs-1 px-xs-0 upload-icons",
              attrs: {
                "aria-label": _vm.i18n.iconAttachmentTitle,
                title: _vm.i18n.iconAttachmentTitle
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-7 px-xs-0" }, [
              _c("div", { staticClass: "col-xs-12 px-xs-0 file-name" }, [
                _vm._v(_vm._s(_vm.getFileName(file.name)))
              ]),
              _vm._v(" "),
              _vm.isContactUs
                ? _c("div", { staticClass: "col-xs-12 px-xs-0 file-size" }, [
                    _vm._v(
                      _vm._s(_vm.i18n.fileSize) +
                        _vm._s(_vm.fileSize) +
                        " " +
                        _vm._s(_vm.fileSizeUnit)
                    )
                  ])
                : _c("div", { staticClass: "col-xs-12 px-xs-0 file-size" }, [
                    _vm._v(
                      _vm._s(_vm.i18n.fileSize) +
                        _vm._s(file.size) +
                        " " +
                        _vm._s(_vm.i18n.fileSizeUnit)
                    )
                  ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-xs-4 delete-link pl-xs-0",
                on: {
                  click: function($event) {
                    _vm.deleteAttachment(index, $event)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.i18n.removeText))]
            )
          ]
        )
      }),
      _vm._v(" "),
      _vm.isError || _vm.typeError
        ? _c("div", { staticClass: "col-xs-12 px-xs-0 pt-xs-3 error-msg" }, [
            _vm._v(_vm._s(_vm.errorMsg))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.duplicateNameError
        ? _c("div", { staticClass: "col-xs-12 px-xs-0 pt-xs-3 error-msg" }, [
            _vm._v(_vm._s(_vm.i18n.duplicateFileNameError))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-center col-xs-12 pt-xs-5" },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-primary modal-btn-primary-small",
              attrs: {
                disabled:
                  _vm.isError || !_vm.files.length || _vm.duplicateNameError,
                "aria-label": _vm.i18n.upload
              },
              on: {
                click: function($event) {
                  _vm.uploadFile($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.i18n.upload))]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3fcc707b", { render: render, staticRenderFns: staticRenderFns })
  }
}