export default {
  name: 'vx-contact-file-upload',
  data() {
    return {
      selectedFiles: [],
      errorMessage: '',
      errorFlag: false,
      fileNames: '', // To store the names of the selected files
    };
  },
  methods: {
    triggerFileSelect() {
      this.$refs.fileInput.click();
    },
    handleFileSelect(event) {
      const files = Array.from(event.target.files);
      const maxSize = 5 * 1024 * 1024; // 5 MB
      let currentTotalSize = this.selectedFiles.reduce((total, file) => total + file.size, 0);
      for (const file of files) {
        this.selectedFiles.push(file);
        currentTotalSize += file.size;
        if (currentTotalSize >= maxSize) {
          this.errorMessage = 'Error: Total file size exceeds 5 MB';
          this.errorFlag = true;
        } else {
          this.errorFlag = false;
        }
      }

      // Update the file names displayed in the text input
      this.fileNames = this.selectedFiles.map((file) => file.name).join(', ');

      // Reset the input field to allow selecting the same file again
      this.$refs.fileInput.value = '';
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);

      // Update the file names displayed in the text input
      this.fileNames = this.selectedFiles.map((file) => file.name).join(', ');

      const totalSize = this.selectedFiles.reduce((total, file) => total + file.size, 0);
      if (totalSize <= 5 * 1024 * 1024) {
        this.errorMessage = '';
        this.errorFlag = false;
      }
    },
    formatFileSize(size) {
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return `${(size / (1024 ** i)).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
    },
  },
  // Emit selected files to the parent component
  watch: {
    selectedFiles(newFiles) {
      this.$emit('update:selectedFiles', newFiles);
    },
    errorFlag(newMessage) {
      this.$emit('update:errorFlag', newMessage);
    },
  },
};
